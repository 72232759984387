@import "styles/_mixin.scss";
@import "styles/_colors.scss";
@import "styles/_breakpoints.scss";

.mainWrapper {
  width: 100%;
  height: 95vh;
  background-image: url(https://raw.githubusercontent.com/haseeb058/assets-repo/main/force_convertor_bg.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .innerWrapper {
    width: 900px;
    .landingTitle {
      color: white;
      font-size: 34px;
      font-weight: 700;
      @media (max-width: $xxs) {
        font-size: 20px;
      }
    }
    .btnContactUs {
      margin: 30px 0px;
      max-width: 190px;
      &:hover {
        background-color: white;
        border: 1px solid #2bb572;
        color: #2bb572;
        transition: ease-in-out 0.2s;
      }
    }
  }
}

.footerWrapper {
  display: flex;
  padding: 10px 80px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $xxs) {
    padding: 10px 20px;
  }
  .companyInfo,
  .addressInfo {
    font-size: 13px;
    font-weight: 400;
  }
}
