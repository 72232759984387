.wrapperClass {
  max-width: 550px !important;

  .headingClass {
    border-bottom: 1px solid #dedede;
    h2 {
      font-weight: 600;
      font-size: 38px;
      padding: 10px;
      line-height: 24px;
      margin-bottom: 20px;
      color: #1a422f;
      text-align: center;
    }
  }
  .footerDiv {
    border-top: 1px solid #dedede;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }
}

.inputClass {
  border-color: #47b881;
}

.btnLogin {
  min-width: 130px;
  font-size: 16px !important;
  font-weight: 400 !important;
  &:hover {
    background-color: white;
    border: 1px solid #2bb572;
    color: #2bb572;
    transition: ease-in-out 0.2s;
  }
}

.btnCancel {
  background-color: #dedede !important;
  color: black;
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
  &:hover {
    background-color: #b8b3b3 !important;
  }
}
