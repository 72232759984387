.modalWrapper {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  z-index: 250000;
  padding: 20px 20px;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));

  & .modalContentWrapper {
    position: relative;
    overflow-y: auto;
    max-width: 650px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    padding: 20px;
    background: white;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    @media (max-width: 675px) {
      padding: 30px 20px;
    }
    &::-webkit-scrollbar:vertical {
      display: none;
    }
  }
}
