@import "styles/_breakpoints.scss";
@import "styles/_colors.scss";
@import "styles/_mixin.scss";

.wrapperClass {
  max-width: 650px !important;
  .headingClass {
    border-bottom: 1px solid #dedede;
    h2 {
      font-weight: 600;
      font-size: 38px;
      padding: 20px 10px;
      line-height: 24px;
      color: #1a422f;
      text-align: center;
    }
  }
  .footerDiv {
    border-top: 1px solid #dedede;
    padding: 20px 10px 0px;
    display: flex;
    margin-top: 25px;
    align-items: center;
    justify-content: flex-end;
  }
}

.inputClass {
  border-color: #47b881;
}

.btnLogin {
  min-width: 170px;
  font-size: 16px !important;
  font-weight: 400 !important;
  &:hover {
    background-color: white;
    border: 1px solid #2bb572;
    color: #2bb572;
    transition: ease-in-out 0.2s;
  }
}

.btnCancel {
  background-color: #dedede !important;
  color: black;
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
  &:hover {
    background-color: #b8b3b3 !important;
  }
}

.gridFour {
  margin-top: 10px;
  @include gridFour(10, 10);
}

.imgWrapper {
  width: 100%;
  height: 250px;
  border: 1px solid #dedede;
  margin-top: 25px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    border-radius: 15px;
    width: 100%;
    height: 100%;
  }
}

.addSubProduct,
.disableSubProduct {
  min-width: 180px;
}

.btnRemoveField {
  width: 100%;
  height: 28px;
  max-width: 25px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  &:hover {
    background-color: #f26d6d;
  }
}

.disableSubProduct {
  background-color: #b8b3b3 !important;
  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
}

.btnRemoveImg {
  width: 100%;
  height: 28px;
  max-width: 25px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  &:hover {
    background-color: #f26d6d;
  }
}
