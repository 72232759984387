@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "styles/_breakpoints.scss";

html {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  list-style: none;
  outline: none;
}

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  margin: 0px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.5rem;
  margin-right: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
  // border: 1px solid #d6d4d4;
  border-radius: 100vw;
  margin-block: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1cd;
  border: -0 solid hsla(120, 75%, 50%, 1);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: #e7e5e5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #eb7d7d !important;
  border-radius: 0px !important;
}

.react-datepicker__header {
  background-color: transparent !important;
  border-bottom: none !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-bottom: 16px;
}

.data-table-extensions-action {
  display: none;
}

.filter-text {
  border-bottom: 1px solid #2bb572 !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 10px solid rgb(0, 0, 0) !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 10px solid rgb(0, 0, 0) !important;
}

.Toastify {
  position: absolute;
  z-index: 999999;
}
