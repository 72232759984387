@import "styles/_breakpoints.scss";
@import "styles/_colors.scss";
@import "styles/_mixin.scss";

.imgWrapper {
  text-align: center;
  margin: 10px auto;
  .profileImg {
    border-radius: 100%;
  }
}
.navTab {
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    li {
      padding: 10px;
      border-bottom: 1px solid #fafafa;
      margin: 0px 4px;
      &:hover {
        cursor: pointer;
        border-bottom-color: #2bb572;
      }
    }
  }
}

.footerWrapper {
  display: flex;
  padding: 10px 80px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $xxs) {
    padding: 10px 20px;
  }
  .companyInfo,
  .addressInfo {
    font-size: 13px;
    font-weight: 400;
  }
}

.active {
  border-bottom-color: #2bb572 !important;
}

.imgDivWrapper {
  width: 100%;
  height: 250px;
  border: 1px solid #dedede;
  margin-top: 25px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
}

.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
  .btnUpdate,
  .btnRemove {
    min-width: 210px;
  }
  .btnRemove {
    margin-left: 10px;
    background-color: #f26d6d;
  }
  .btnUpdate {
    margin-top: 10px;
  }
}
