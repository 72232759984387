.select {
  background: #ffffff;
  color: #626579;
  box-shadow: none;
  outline: none;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  font-weight: 400;
  padding: 6px 14px;
  height: 45px;
  cursor: pointer;
  option {
    font-size: 14px;
    color: #6c757d;
  }
}

label {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #626579 !important;
}

.errorMessage {
  color: #f26d6d;
  font-size: 14px;
  position: absolute;
  display: block;
  &::first-letter {
    text-transform: capitalize;
  }
}
