@import "styles/_mixin.scss";
@import "styles/_colors.scss";
@import "styles/_breakpoints.scss";

.mainWrapper {
  margin: 130px auto;
  .contentWrapper {
    @media (max-width: $xxs) {
      width: 100%;
    }
  }
}

.btnSearch {
  height: 100%;
  min-height: 45px;
  margin-left: 10px;
  @media (max-width: $xxs) {
    margin: 0px;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 80px;
  }
}

.totalCount {
  margin: 0;
  padding: 0;
  color: rgb(58, 54, 54);
  margin-top: 5px;
}

.searchInput {
  margin: 0;
  width: 100%;
}

.searchWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 40px;
  @media (max-width: $xxs) {
    position: relative;
    padding-top: 0px;
  }
}

.innerInput {
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
}

.footerWrapper {
  display: flex;
  padding: 10px 80px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $xxs) {
    padding: 10px 20px;
  }
  .companyInfo,
  .addressInfo {
    font-size: 13px;
    font-weight: 400;
  }
}

.loader {
  border-top: 2px solid #2bb57292;
  border-left: 2px solid #2bb57292;
  border-right: 2px solid transparent;
  border-bottom: 2px solid #2bb57292;
  width: 50px;
  height: 50px;
  margin: 10px auto;
}

.cardList {
  @include gridTwo(10, 10);
  border-radius: 5px;
  margin-top: 10px;
  align-items: center;

  .imgWrapper {
    @media (max-width: $xxs) {
      width: 100%;
      display: flex;
      .logo {
        margin: auto;
        width: 100% !important;
        height: 220px !important;
      }
    }
    .logo {
      width: 100%;
      height: 400px;
      border-radius: 10px;
      padding: 0px 5px;
    }
  }
  .contentWrapper {
    .title {
      text-align: center;
      color: #2bb572c9;
      font-size: 29px;
      &:hover {
        cursor: pointer;
      }
      @media (max-width: $xxs) {
        font-size: 22px;
      }
    }
    .detailWrapper {
      .row {
        display: flex;
        padding: 5px;
        p {
          width: 100%;
        }
        .value {
          @media (max-width: $xxs) {
            text-align: center;
          }
        }
      }
    }
    .ButtonWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0px;
    }
  }
}
.loader {
  border-top: 2px solid #2bb57292;
  border-left: 2px solid #2bb57292;
  border-right: 2px solid transparent;
  border-bottom: 2px solid #2bb57292;
  width: 50px;
  height: 50px;
  margin: 10px auto;
}

.accordionWrapper {
  margin: 10px auto;
}

.btnDelete {
  margin-left: 10px;
  background-color: transparent;
  border: 1px solid #f26d6d;
  color: #f26d6d;
  &:hover {
    background-color: #f26d6d;
    color: white;
  }
}

.cardFilterList {
  width: 100%;
  max-height: 200px;
  margin-top: 10px;
  overflow: auto;
  border-radius: 5px;
  padding: 5px !important;
  .data {
    padding: 5px;
    margin: 3px auto;
    &:hover {
      border-radius: 5px;
      cursor: pointer;
      color: white;
      background-color: #2bb57292;
    }
  }
}
