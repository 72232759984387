@import "styles/_breakpoints.scss";
@import "styles/_colors.scss";
@import "styles/_mixin.scss";

.gridTwoWrapper {
  @include gridTwo(20, 20);
}
.gridThreeWrapper {
  @include gridThree(20, 20);
  margin-top: 10px;
}

.btnUpdate {
  margin-top: 25px !important;
}

.tableWrapper {
  margin-top: 20px;
}

.footerWrapper {
  display: flex;
  padding: 10px 80px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $xxs) {
    padding: 10px 20px;
  }
  .companyInfo,
  .addressInfo {
    font-size: 13px;
    font-weight: 400;
  }
}

.loadingTable {
  width: 80px;
  height: 80px;
  border-color: #2bb572;
  border-right: 2px solid transparent;
  margin: 70px auto;
  @media (max-width: $md) {
    width: 60px;
    height: 60px;
    margin: 50px auto;
  }
  @media (max-width: $sm) {
    width: 45px;
    height: 45px;
    margin: 25px auto;
  }
}

.btnEdit,
.btnDelete {
  max-width: 50px;
}

.btnEdit {
  background-color: #2ea96e;
  &:hover {
    background-color: #2bb572dd;
  }
}

.btnDelete {
  margin-left: 10px;
  background-color: #e43c3c;
  &:hover {
    background-color: #f26d6d;
  }
}
