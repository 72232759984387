@import "styles/_mixin.scss";
@import "styles/_colors.scss";
@import "styles/_breakpoints.scss";

.navbar {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 2;
  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.25);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(238, 237, 237);
  @media (max-width: $sm) {
    padding: 20px 10px;
  }
  @media (max-width: $xxs) {
    padding: 10px;
  }
  .titleDiv {
    display: flex;
    align-items: center;
    a {
      .logo {
        width: 220px;
        height: 80px;
        @media (max-width: $sm) {
          width: 120px;
          height: 60px;
        }
      }
    }
  }
  .flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    li {
      display: flex;
      align-items: center;
      margin: 0 10px;
      @media (max-width: 310px) {
        margin: 0 8px;
      }
      a {
        text-decoration: none;
        color: #2c2c2c;
        &:hover {
          color: #2bb572;
        }
      }
      img {
        width: 18px;
        height: 20px;
        cursor: pointer;
      }
      .profileDiv {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        .roundDiv {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #e4e7eb;
        }
        .arrowImg {
          width: 10px;
          height: 6px;
          margin-left: 12px;
        }
      }
    }
  }
}

.btnLogin {
  min-width: 130px;
  font-size: 16px !important;
  font-weight: 400 !important;
  &:hover {
    background-color: white;
    border: 1px solid #2bb572;
    color: #2bb572;
    transition: ease-in-out 0.2s;
  }
}

.loginUserWrapper {
  position: "relative";
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  .navbarLogin {
    display: flex;
    align-items: center;
    cursor: pointer;
    .innerDiv {
      display: flex;
      align-items: center;
      img {
        height: 45px;
        width: 45px;
        margin-left: 0px;
        border-radius: 100%;
      }
      h6 {
        margin-left: 15px;
        color: white;
        font-size: 14px;
        font-weight: 500;
      }
      p {
        color: #2c2c2c;
        margin-left: 15px;
        font-weight: 400;
        margin-top: 4px;
        font-size: 13px;
      }
    }
    img {
      height: 15px;
      width: 15px;
      margin-left: 20px;
    }
  }
}

.navMenuWrapper {
  margin-right: 20px;
  display: flex;

  @media (max-width: $sm) {
    display: none;
  }
}

.infoWrapper {
  @media (max-width: $xxs) {
    display: none;
  }
}
