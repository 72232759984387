.loader_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.loaderClass {
  width: 55px;
  height: 55px;
  margin: 0px;
  border-right: 4px solid transparent;
  border-top: 4px solid #2bb572;
  border-left: 4px solid #2bb572;
  border-bottom: 4px solid #2bb572;
}
