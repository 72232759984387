@import "styles/breakpoints";

.layoutWrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  aside {
    position: fixed;
    width: 250px;
    background: transparent;
    z-index: 25000;
    transition: all 0.3s;
    @media (max-width: $sm) {
      left: -100%;
    }
    .backdropDiv {
      position: fixed;
      z-index: 1;
      inset: 0;
      background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    }
  }
  main {
    margin-left: 250px;
    height: 100vh;
    @media (max-width: $sm) {
      margin-left: 0;
    }
  }
}

.sideBarOpen {
  left: 0px !important;
}
