@import "styles/_breakpoints.scss";
@import "styles/_colors.scss";
@import "styles/_mixin.scss";
.navbar {
  position: sticky;
  top: 0px;
  z-index: 2;
  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  display: flex;
  background-color: white;
  @media (max-width: $sm) {
    padding: 20px 10px;
  }
  .titleDiv {
    display: flex;
    align-items: center;
    .menuIcon {
      cursor: pointer;
      display: none;
      @media (max-width: $sm) {
        display: block;
        margin-right: 10px;
      }
    }
  }
  .flex {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    li {
      display: flex;
      align-items: center;
      margin: 0 10px;
      @media (max-width: 310px) {
        margin: 0 8px;
      }
      img {
        width: 18px;
        height: 20px;
        cursor: pointer;
      }
      .profileDiv {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        .roundDiv {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #e4e7eb;
        }
        .arrowImg {
          width: 10px;
          height: 6px;
          margin-left: 12px;
        }
      }
    }
  }
}

.loginUserWrapper {
  position: "relative";
  background-color: rgb(238, 237, 237);
  padding: 10px 20px;
  border-radius: 10px;
  .navbarLogin {
    display: flex;
    align-items: center;
    cursor: pointer;
    .innerDiv {
      display: flex;
      align-items: center;
      img {
        height: 45px;
        width: 45px;
        margin-left: 0px;
        border-radius: 100%;
      }
      h6 {
        margin-left: 15px;
        color: white;
        font-size: 14px;
        font-weight: 500;
      }
      p {
        color: #2c2c2c;
        margin-left: 15px;
        font-weight: 400;
        margin-top: 4px;
        font-size: 13px;
      }
    }
    img {
      height: 15px;
      width: 15px;
      margin-left: 20px;
    }
  }
}
