@import "styles/_breakpoints.scss";
@import "styles/_colors.scss";
@import "styles/_mixin.scss";

.btnUpdate {
  margin-top: 25px !important;
  min-width: 160px;
}

.tableWrapper {
  margin-top: 20px;
}

.footerWrapper {
  display: flex;
  padding: 10px 80px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $xxs) {
    padding: 10px 20px;
  }
  .companyInfo,
  .addressInfo {
    font-size: 13px;
    font-weight: 400;
  }
}

.loadingTable {
  width: 80px;
  height: 80px;
  border-color: #2bb572;
  border-right: 2px solid transparent;
  margin: 70px auto;
  @media (max-width: $md) {
    width: 60px;
    height: 60px;
    margin: 50px auto;
  }
  @media (max-width: $sm) {
    width: 45px;
    height: 45px;
    margin: 25px auto;
  }
}

.btnEdit,
.btnDelete {
  max-width: 50px;
}

.btnEdit {
  background-color: #2ea96e;
  &:hover {
    background-color: #2bb572dd;
  }
}

.btnDelete {
  margin-left: 10px;
  background-color: #e43c3c;
  &:hover {
    background-color: #f26d6d;
  }
}

.tableImgWrapper {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.row {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  background-color: #f8f9fa;
  .cardDetails {
    border-radius: 5px;
    padding: 5px;
    margin-right: 15px;
    min-width: 350px !important;
    .header {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
    }
    .bodyWrapper {
      table {
        border-collapse: collapse;
        width: 100%;
      }

      td,
      th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }

      tr:nth-child(even) {
        background-color: #dddddd;
      }
    }
  }
}
