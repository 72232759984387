@import "styles/_breakpoints.scss";
@import "styles/_colors.scss";
@import "styles/_mixin.scss";

.mainWrapper {
  height: 100vh;
}

.gridTwoWrapper {
  @include gridTwo(20, 20);
}
.gridThreeWrapper {
  @include gridThree(20, 20);
  margin-top: 10px;
}

.btnUpdate {
  margin-top: 25px !important;
}

.footerWrapper {
  display: flex;
  padding: 10px 80px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $xxs) {
    padding: 10px 20px;
  }
  .companyInfo,
  .addressInfo {
    font-size: 13px;
    font-weight: 400;
  }
}
