.modal {
  max-width: 350px !important;
  padding: 20px 30px !important;

  .main {
    text-align: center;
    .contentDiv {
      h1 {
        font-size: 32px;
        font-weight: 700;
        line-height: 24px;
        color: #315fb8;
      }
      p {
        font-size: 14px;
        margin: 30px 0;
        color: #9ea0a5;
        line-height: 16px;
        strong {
          color: #1d1d1f;
        }
      }
    }
    .actionDiv {
      display: flex;
      justify-content: center;
      button {
        width: 100px;
        height: 42px;

        span {
          font-size: 16px;
          font-weight: 600;
        }
      }
      .deleteBtn {
        margin-left: 20px;
        background: #f26d6d;
      }
      .cancelBtn {
        background: transparent;
        color: #1d1d1f !important;
      }
    }
  }
}
