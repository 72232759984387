@import "styles/_breakpoints.scss";
@import "styles/_colors.scss";
@import "styles/_mixin.scss";

.mainDiv {
  position: fixed;
  background-color: transparent;
  inset: 0;
  z-index: 2000;
}

.mainInner {
  width: 100%;
  z-index: 2500;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  p {
    color: #565656;
    margin-bottom: 10px;
    padding: 10px 5px;
    font-weight: 500;
    &:hover {
      background-color: #f4f4f4;
    }
  }
}

.navMenuWrapper {
  display: none;
  @media (max-width: $sm) {
    display: block;
  }
}
