@import "styles/_breakpoints.scss";
@import "styles/_colors.scss";
@import "styles/_mixin.scss";

.inputContainer {
  position: relative;
  margin-top: 8px;
  label {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #626579;
  }
  input {
    border-radius: 5px;
    padding: 10px 8px;
    width: 100%;
    height: 45px;
    background-color: #ffffff;
    color: black;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #4142429a;
      @media (max-width: $md) {
        color: #574e4e8c;
        font-size: 14px;
        font-family: Roboto;
      }
    }
  }
  .icon {
    position: absolute;
    right: 11px;
    top: 8px;
  }
  .errorMessage {
    color: $danger;
    font-size: 14px;
    position: absolute;
    display: block;
    &::first-letter {
      text-transform: capitalize;
    }
  }
}
