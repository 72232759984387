@import "styles/_mixin.scss";
@import "styles/_colors.scss";
@import "styles/_breakpoints.scss";

.mainWrapper {
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/engin1.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .landingTitle {
    color: white;
    font-size: 34px;
    font-weight: 700;
    @media (max-width: $xxs) {
      font-size: 20px;
    }
  }
}

.btnSearch {
  height: 100%;
  min-height: 45px;
  margin-left: 10px;
  @media (max-width: $xxs) {
    margin: 0px;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 80px;
  }
}

.totalCount {
  margin: 0;
  padding: 0;
  color: white;
  margin-top: 5px;
}

.searchInput {
  margin: 0;
  width: 100%;
}

.searchWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 20px;
  @media (max-width: $xxs) {
    position: relative;
    padding-top: 0px;
  }
}

.innerInput {
  font-size: 18px;
  font-weight: 300;
  padding: 10px;
}

.footerWrapper {
  display: flex;
  padding: 10px 80px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $xxs) {
    padding: 10px 20px;
  }
  .companyInfo,
  .addressInfo {
    font-size: 13px;
    font-weight: 400;
  }
}

.cardList {
  width: 100%;
  max-height: 300px;
  margin-top: 10px;
  overflow: auto;
  border-radius: 5px;
  padding: 5px !important;
  .data {
    padding: 5px;
    margin: 3px auto;
    &:hover {
      border-radius: 5px;
      cursor: pointer;
      color: white;
      background-color: #2bb57292;
    }
  }
}

.loader {
  border-top: 2px solid #2bb57292;
  border-left: 2px solid #2bb57292;
  border-right: 2px solid transparent;
  border-bottom: 2px solid #2bb57292;
  width: 50px;
  height: 50px;
  margin: 10px auto;
}
